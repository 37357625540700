import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { useLatest } from 'react-use';

import {
  EnumsGamePackRecommendationScenario,
  EnumsPlatform,
} from '@lp-lib/api-service-client/public';

import {
  AudienceControlMsgReceiverProvider,
  AudienceControlMsgSenderProvider,
} from '../../components/Audience/AudienceControlProviders';
import { BotProvider } from '../../components/Bot';
import { BroadcastProvider } from '../../components/Broadcast/BroadcastProvider';
import { ChatSharedContextProvider } from '../../components/Chat/SharedContext';
import { useExtSendChatNotifs } from '../../components/ChatNotifs/ChatNotifs';
import { CohostVideoMixer } from '../../components/Cohost/cohost-video-mixer';
import { CohostDeviceCheckModal } from '../../components/Cohost/CohostConfiguration';
import { CohostPanel } from '../../components/Cohost/CohostPanel';
import {
  CohostPositionManagerProvider,
  CohostSynchronizeMixerStatus,
  CohostSynchronizePosition,
  CohostSynchronizeVisibility,
} from '../../components/Cohost/CohostPositionManagerProvider';
import { CohostStreamView } from '../../components/Cohost/CohostStreamView';
import { CohostSynchronizeOrgLogo } from '../../components/Cohost/CohostSynchronizeOrgLogo';
import { VideoEffectsSettingsCohostStore } from '../../components/Cohost/CohostVideoEffectsSettingsStore';
import {
  defaultCohostVBGSettings,
  defaultCohostVideoEffectsSettings,
} from '../../components/Cohost/utils';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../../components/ConfirmCancelModalContext';
import { ContextMenu } from '../../components/ContextMenu';
import { ContextMenuProvider } from '../../components/ContextMenu/Context';
import {
  CrowdFramesExtractor,
  CrowdFramesProvider,
} from '../../components/CrowdFrames';
import { DeviceStateWatchdog } from '../../components/Device';
import { DeviceCheckModal } from '../../components/Device/Check';
import { PlayerVideoMixer } from '../../components/Device/video-stream-mixer';
import { EmojisAnimationDrawer } from '../../components/EmojiBoard/EmojiDrawer';
import { ExperienceMetricsProvider } from '../../components/ExperienceMetrics';
import { ExperienceScoreProvider } from '../../components/ExperienceScore';
import {
  type FirebaseEvents,
  type FirebaseService,
  useFirebaseContext,
  useInitFirebase,
  useIsFirebaseConnected,
} from '../../components/Firebase';
import { FirebaseReconnectModal } from '../../components/Firebase/ReconnectModal';
import { FirebaseLatencyProvider } from '../../components/FirebaseLatency/Provider';
import { FrameRateMeasure } from '../../components/FrameRateMeasure/FrameRateMeasure';
import { BlockProviders } from '../../components/Game/Blocks';
import { BlockOutputsClearer } from '../../components/Game/Blocks/Common/block-outputs';
import { OndGamePlayOverlay } from '../../components/Game/Blocks/Common/GamePlay';
import { GamePlayUIConfigurationProvider } from '../../components/Game/Blocks/Common/GamePlay/GamePlayUIConfigurationProvider';
import { GameSessionPreconfigProvider } from '../../components/Game/Blocks/Common/GamePlay/GameSessionPreconfigProvider';
import { BlockLifecycleRulesEvaluatorProvider } from '../../components/Game/Blocks/Common/LifecycleRules/BlockLifecycleRulesEvaluator';
import {
  GameLibrary,
  GameLibraryProvider,
} from '../../components/Game/GameLibrary';
import { GameCoverClip } from '../../components/Game/GamePackCoverPres';
import { GameSessionBundle } from '../../components/Game/GameSessionBundle';
import { GameStreamingStatusProvider } from '../../components/Game/GameStreamingStatusProvider';
import { OnDGameControlProvider } from '../../components/Game/OndGameControl';
import { PostGameProvider } from '../../components/Game/PostGame/Provider';
import { PreGameProvider } from '../../components/Game/PreGame/Provider';
import { PrePostGamePriority } from '../../components/Game/PrePostGamePriority';
import { ScaleFontSizeForSmallWindowsHack } from '../../components/Game/ScaleFontSizeForSmallWindowsHack';
import {
  GameLogMonitorBrandsBlocks,
  GameLogMonitorScoreboard,
  GameLogMonitorSession,
  GameLogMonitorTeamCreation,
  GameLogMonitorTeamNames,
  GameLogMonitorUserJoined,
  GameLogSynchronizeCommonProperties,
} from '../../components/GameLog/GameLogComponents';
import { HostIndicator } from '../../components/Host/HostIndicator';
import { MiddlePanel } from '../../components/Layout';
import { RightPanel } from '../../components/Layout/RightPanel';
import { SafeZoneProvider } from '../../components/Layout/SafeZoneProvider';
import { LayoutAnchorProvider } from '../../components/LayoutAnchors/LayoutAnchors';
import { useLiteModeEnabled } from '../../components/LiteMode';
import { LiteModeNotificationDispatcher } from '../../components/LiteModeNotification';
import { Lobby, LobbyProvider } from '../../components/Lobby';
import { LobbyWidgetKey } from '../../components/Lobby/LobbyGameWidgets';
import { MediaControls } from '../../components/MediaControls';
import { MusicPlayerProvider } from '../../components/MusicPlayer/Context';
import {
  MultipleUserInstancesWarning,
  useInitMultipleUserInstancesWatcher,
  useMultipleUserInstancesContext,
} from '../../components/MutipleInstances';
import {
  NotificationContextProvider,
  useNotificationDataSourceRedux,
} from '../../components/Notification/Context';
import {
  LaunchOnDGameLocalControl,
  OnDGameHostingManagerProvider,
  OndHostingWarning,
  useInitOnDGameHosting,
} from '../../components/OnDGameHosting';
import { OndGameBootstrapUIControlProvider } from '../../components/OnDGameUIControl';
import { OndPlayTestPanel } from '../../components/OnDGameUIControl/OndPlayTestPanel';
import {
  PairingGameControl,
  PairingGameProvider,
} from '../../components/Pairing';
import { MarkedAsAway } from '../../components/Participant/MarkedAsAway';
import { PersistentPointsProvider } from '../../components/PersistentPoints/Provider';
import { PixelFxProvider } from '../../components/PixelFx/PixelFxProvider';
import { PixelFxSceneProvider } from '../../components/PixelFx/PixelFxSceneProvider';
import { PlaybackModeProvider } from '../../components/PlaybackMode/Provider';
import { useMyInstance } from '../../components/Player';
import { IntrosGameTracking } from '../../components/Program/Intros';
import { ProvidersList } from '../../components/ProvidersList';
import { RemoteRefreshProvider } from '../../components/RemoteRefresh/RemoteRefreshProvider';
import { RightPanelProvider } from '../../components/RightPanelContext';
import {
  SentimentDetectorLauncher,
  SentimentProvider,
} from '../../components/Sentiment/Context';
import { SessionTracking } from '../../components/Session';
import { SettingsModal } from '../../components/Settings/Settings';
import { type MenuKey } from '../../components/Settings/types';
import { SFXProvider } from '../../components/SFX';
import { Stage, useInitStage } from '../../components/Stage';
import { StreamingToolsProvider } from '../../components/StreamingTools/StreamingToolsContext';
import {
  SwitchNotice,
  SwitchNoticeProvider,
} from '../../components/SwitchNotice';
import {
  useEnsureTeamMemberRemovedWhenDisconnected,
  useEnsureUpdateTeamInfoInLocalStorage,
} from '../../components/TeamAPI/TeamV1';
import { TeamCaptainScribeNotificationDispatcher } from '../../components/TeamCaptainScribe/TeamCaptainScribeNotification';
import {
  TeamRandomizer,
  TeamRandomizerProvider,
} from '../../components/TeamRandomizer';
import { TeamSizeContextProvider } from '../../components/TeamSizeControl/Context';
import { maxTeamMembers } from '../../components/TeamSizeControl/utils';
import {
  LiveGameTownhallLargeGroupSwticher,
  TownhallProvider,
} from '../../components/Townhall';
import {
  isGuest,
  useUser,
  useUserContext,
  useUserStates,
} from '../../components/UserContext';
import {
  useInitVenueEvent,
  useMarkVenueInitCompleted,
  useMyClientId,
  useMyClientType,
  useParamVenueId,
  useSubscribeVenueEvent,
  useVenue,
  VenueBootstrap,
  VenueContextProvider,
  VenueInitLoading,
  VenueInitProvider,
  VenueSetup,
} from '../../components/Venue';
import { useFirebaseVenueSyncing } from '../../components/Venue/useFirebaseVenueSyncing';
import { WebRTCTest } from '../../components/Venue/VenueWebRTCTest';
import { VenueOrgLogoAverageColorProvider } from '../../components/VenueOrgLogoAverageColor/VenueOrgLogoAverageColorProvider';
import {
  useVideoEffectsSettingsStore,
  VideoEffectsSettingsDummyStore,
} from '../../components/VideoEffectsSettings/Storage';
import { AdHocVOMsgReceiverProvider } from '../../components/VoiceOver/AdHocVOMsgProviders';
import {
  LocalizedVoiceoverPlayerProvider,
  LocalizedVoiceoversRTCPublisherProvider,
} from '../../components/VoiceOver/LocalizedVoiceOvers';
import { SubtitlesManagerProvider } from '../../components/VoiceOver/SubtitlesManagerProvider';
import {
  DebugStreams,
  MicMutedWarning,
  RTCServiceContextProvider,
  useAudienceRTCServiceMap,
  useDummyAudienceRTCServiceMap,
  useInitWebRTCMVMProcessor,
} from '../../components/WebRTC';
import {
  useZoomEnv,
  useZoomGuestFeatureChecker,
} from '../../components/Zoom/utils';
import {
  ZoomDeviceCheck,
  ZoomVenueProvider,
} from '../../components/Zoom/ZoomVenueProvider';
import config from '../../config';
import { useEnsureUnlockedAudioContext } from '../../hooks/useEnsureUnlockedAudioContext';
import {
  getFeatureQueryParam,
  useFeatureQueryParam,
} from '../../hooks/useFeatureQueryParam';
import { useUpdateLoggerWithStreamSessionId } from '../../hooks/useInitLogger';
import { useInitReduxSlice } from '../../hooks/useInitRedux';
import { useInitSentryContext } from '../../hooks/useInitSentryContext';
import {
  useInitGameSession,
  useInitStreamSession,
} from '../../hooks/useInitSession';
import { useInterceptNavigatorMediaSessionKeys } from '../../hooks/useInterceptNavigatorMediaSessionKeys';
import { useIsController, useIsCoordinator } from '../../hooks/useMyInstance';
import { usePageTitle } from '../../hooks/usePageTitle';
import { useRSCmp } from '../../hooks/useRSCmp';
import { useUserWatcher } from '../../hooks/useUserWatcher';
import {
  getAudioContext,
  getEchoCancelledAudioDestination,
} from '../../services/audio/audio-context';
import { type ClientId } from '../../services/crowd-frames';
import { ClientType, type Venue } from '../../types';
import { type EmitterListener } from '../../utils/emitter';
import { Header } from './Header';
import { HostStreamView } from './HostStreamView';
import { OffBoarding } from './OffBoarding';
import { NotificationCenter } from './Team/NotificationCenter';
import { TeamRecovery } from './Team/Recovery';
import { TeamFullModel } from './Team/TeamFullModel';
import { UserRequired } from './UserRequired';
import { userIconRenderable } from './utils';
import { VenueCapacityCheck } from './VenueCapacityCheck';

// Purposefully avoiding returning the entire object to prevent excessive
// comparisons/rerenders.
function useHasMe() {
  const me = useMyInstance();
  return !!me?.clientId;
}

type ZoomEnv = ReturnType<typeof useZoomEnv>;

const Audience = (props: {
  zoomEnv?: ZoomEnv;
  cohostEnabled: boolean;
  cohostVMEnabled: boolean;
}): JSX.Element => {
  useRSCmp('audience');

  const { zoomEnv, cohostEnabled, cohostVMEnabled } = props;
  const user = useUser();
  const userId = user.id;
  const { updateUserStates } = useUserContext();
  const clientId = useMyClientId();
  const clientType = useMyClientType();
  const hasMe = useHasMe();
  const { joined } = useUserStates();
  const firebaseConnected = useIsFirebaseConnected();
  const [venue] = useVenue();
  const [showDeviceCheckModal, setShowDeviceCheckModal] = useState(true);
  const notificationDataSource = useNotificationDataSourceRedux();
  const { multipleInstances } = useMultipleUserInstancesContext();
  const isController = useIsController();
  const isCoordinator = useIsCoordinator();
  const { svc, emitter } = useFirebaseContext();
  const liteMode = useLiteModeEnabled();
  const inZoom = !!zoomEnv;
  const overrideFeatureChecker = useZoomGuestFeatureChecker(
    userId,
    inZoom && isGuest(user)
  );
  const disabledLobbyWidgets = useMemo(
    () => (inZoom ? [LobbyWidgetKey.Memories] : undefined),
    [inZoom]
  );
  const [showSettingsModal, setShowSettingsModal] = useState<boolean | MenuKey>(
    false
  );
  const sendChatNotifs = useExtSendChatNotifs();
  const vesStore = useVideoEffectsSettingsStore();

  useEffect(() => {
    if (joined) setShowDeviceCheckModal(false);
  }, [joined]);

  useEffect(() => {
    return () => {
      updateUserStates({ joined: false });
      setShowDeviceCheckModal(true);
    };
  }, [updateUserStates]);

  const isReady = joined && firebaseConnected && hasMe;

  if (multipleInstances) {
    return (
      <MultipleUserInstancesWarning
        clientId={clientId}
        venueName={venue?.displayName}
      />
    );
  }

  const providers = [
    <ExperienceScoreProvider />,
    <SFXProvider />,
    <ConfirmCancelModalProvider />,
    <SwitchNoticeProvider />,
    <LayoutAnchorProvider />,
    <PixelFxProvider />,
    <AudienceRTCServiceProvider clientId={clientId} readyToJoin={isReady} />,
    <CrowdFramesProvider
      liteModeEnabled={liteMode}
      userId={userId}
      myClientId={clientId as ClientId}
      iconRenderable={inZoom ? userIconRenderable : undefined}
    />,
    <NotificationContextProvider datasource={notificationDataSource} />,
    <LobbyProvider ready={isReady} svc={svc} />,
    <PlaybackModeProvider />,
    <PairingGameProvider svc={svc} />,
    <RightPanelProvider />,
    <MusicPlayerProvider
      audioContextGetter={getAudioContext}
      echoCancelledAudioDestinationGetter={getEchoCancelledAudioDestination}
    />,
    <TeamRandomizerProvider venueId={venue.id} />,
    <TeamSizeContextProvider
      venueId={venue.id}
      defaultMaxMembers={maxTeamMembers}
    />,
    liteMode ? null : <SentimentProvider />,
    <ChatSharedContextProvider />,
    <RemoteRefreshProvider />,
    <ContextMenuProvider />,
    <StreamingToolsProvider />,
    <TownhallProvider
      ready={isReady}
      svc={svc}
      autoToggleEnabled={getFeatureQueryParam(
        'townhall-ond-auto-activate-for-v3-game'
      )}
    />,
    <BroadcastProvider ready={isReady} />,
    <GameLibraryProvider />,
    <SubtitlesManagerProvider venueId={venue.id} />,
    <OnDGameControlProvider ready={isReady} svc={svc} emitter={emitter} />,
    <OnDGameHostingManagerProvider svc={svc} sendChatNotifs={sendChatNotifs} />,
    <PreGameProvider />, // ondgamebootstrapui depends on this
    <PostGameProvider />, // ondgamebootstrapui depends on this
    <GameSessionPreconfigProvider />,
    <CohostPositionManagerProvider ready={isReady} svc={svc} />, // ondgamebootstrapui depends on this
    <AudienceControlMsgSenderProvider venueId={venue.id} />,
    <BlockLifecycleRulesEvaluatorProvider />,
    <OndGameBootstrapUIControlProvider
      venueId={venue.id}
      gameLibraryType={inZoom ? 'zoom' : 'bottomPublicLibrary'}
    />,
    <GamePlayUIConfigurationProvider />,
    <PersistentPointsProvider userId={userId} />,
    <PixelFxSceneProvider />,
    <ExperienceMetricsProvider isReady={isReady} />,
    <FirebaseLatencyProvider
      isReady={isReady}
      clientId={clientId as ClientId}
      firebaseService={svc}
    />,
    <BotProvider />,
    <GameStreamingStatusProvider />,
    <AdHocVOMsgReceiverProvider venueId={venue.id} />,
    <SafeZoneProvider top={inZoom ? 'top-30' : undefined} />,
    <AudienceControlMsgReceiverProvider ready={isReady} venueId={venue.id} />,
    <LocalizedVoiceoversRTCPublisherProvider
      venueId={venue.id}
      readyToJoin={isReady}
    />,
    <LocalizedVoiceoverPlayerProvider
      venueId={venue.id}
      readyToJoin={isReady}
    />,
    <VenueOrgLogoAverageColorProvider />,
  ];

  const providerful = (
    <ProvidersList providers={providers}>
      <VenueSetup />
      <GameLogSynchronizeCommonProperties venueId={venue.id} />
      <GameLogMonitorUserJoined />
      <GameLogMonitorScoreboard />
      <GameLogMonitorBrandsBlocks />
      <GameLogMonitorTeamCreation />
      <GameLogMonitorTeamNames />
      <GameLogMonitorSession />
      <BlockOutputsClearer venueId={venue.id} svc={svc} />
      <CohostSynchronizeMixerStatus
        mixerEnabled={cohostEnabled && cohostVMEnabled}
      />
      <CohostSynchronizePosition ready={isReady} vesStore={vesStore} />
      <CohostSynchronizeVisibility ready={isReady} />
      <CohostSynchronizeOrgLogo ready={isReady} />
      <div className='h-full w-full'>
        {showDeviceCheckModal && (
          <>
            {cohostEnabled && cohostVMEnabled ? (
              <CohostDeviceCheckModal />
            ) : inZoom ? (
              <ZoomDeviceCheck
                onContinue={async () => {
                  await updateUserStates({ joined: true });
                }}
              />
            ) : (
              <CapacityAndDeviceCheck
                venueId={venue.id}
                maybeCohost={cohostEnabled}
              />
            )}
          </>
        )}
        {isReady && (
          <>
            <TeamRecovery />
            <PairingGameControl />
            <HostIndicator zIndex='z-35' />
            <MediaControls
              openSettingsModal={
                !inZoom
                  ? (key?: MenuKey | boolean) =>
                      setShowSettingsModal(key ? key : true)
                  : undefined
              }
              showTeamInVolumeBalancer={!inZoom}
              cohostSplitMenuEnabled={cohostEnabled}
              closedCaptionsButtonDisabled={inZoom}
            />
            <Lobby
              waitTeamViewInited
              featureChecker={overrideFeatureChecker}
              disabledWidgets={disabledLobbyWidgets}
            />
            <HostStreamView />
            <CohostStreamView />
            <OffBoarding typeformDisabledReason={inZoom ? 'Zoom' : undefined} />
            {liteMode ? null : <CrowdFramesExtractor />}
            <RightPanel
              chat={inZoom ? 'hidden' : 'enabled'}
              people={inZoom ? 'teamless' : 'enabled'}
            />
            <GameLibrary>
              <PrePostGamePriority
                scenario={
                  inZoom
                    ? EnumsGamePackRecommendationScenario.GamePackRecommendationScenarioZoom
                    : EnumsGamePackRecommendationScenario.GamePackRecommendationScenarioGeneral
                }
                noPostGameSurvey={inZoom}
              />
            </GameLibrary>
            <TeamCaptainScribeNotificationDispatcher />
            {liteMode ? null : <SentimentDetectorLauncher />}
            <BlockProviders>
              <Header
                homeURL={inZoom ? '/zoom/home' : '/home'}
                canGuestUserNavigateHome={inZoom}
              />
              <MiddlePanel
                className='z-30'
                teamView={inZoom ? 'minified' : 'default'}
              />
              <GameSessionBundle />
              {isCoordinator && <OndPlayTestPanel />}
              {isCoordinator && <CohostPanel />}
            </BlockProviders>
            <Stage />
            <EmojisAnimationDrawer />
          </>
        )}
        <OndGamePlayOverlay />
        {isReady && isController && <LaunchOnDGameLocalControl id={clientId} />}
        <TeamFullModel />
        {isReady && <MarkedAsAway />}
        {isReady && <TeamRandomizer />}
        <ContextMenu />
        <DebugStreams />
        <DeviceStateWatchdog />
        {showSettingsModal && (
          <SettingsModal
            initialMenuKey={
              typeof showSettingsModal === 'boolean'
                ? undefined
                : showSettingsModal
            }
            onClose={() => setShowSettingsModal(false)}
          />
        )}
        <ConfirmCancelModalRoot />
        <LiteModeNotificationDispatcher />
        {isReady && <LiveGameTownhallLargeGroupSwticher />}
        <SwitchNotice />
        {isReady && (
          <SessionTracking
            config={config.session}
            platform={inZoom ? EnumsPlatform.PlatformZoom : undefined}
          />
        )}
        {isReady && <IntrosGameTracking />}
        <FrameRateMeasure />
        <NotificationCenter />
        {joined && <FirebaseReconnectModal />}
        <GameCoverClip id='game-cover-clip' />
        <OndHostingWarning />
        {isReady && <MicMutedWarning />}
        {isReady && !inZoom && <WebRTCTest />}
      </div>
      <ScaleFontSizeForSmallWindowsHack />
    </ProvidersList>
  );

  return (
    <>
      <ReturnlessHooks
        venue={venue}
        clientId={clientId}
        clientType={clientType}
        isController={isController}
        isCoordinator={isCoordinator}
        svc={svc}
        emitter={emitter}
        zoomEnv={zoomEnv}
        cohostEnabled={cohostEnabled}
      />
      {providerful}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default function AudienceWrapper(): JSX.Element {
  const zoomEnv = useZoomEnv();
  const vbgEnabled = useFeatureQueryParam('virtual-background');
  const vbgEffects = useLatest(useUserStates().virtualBackgroundEffects);
  const cohostEnabled = useFeatureQueryParam('cohost');
  const cohostVMEnabled = useFeatureQueryParam('cohost-video-mixer');
  const { updateVirtualBackgroundEffects } = useUserContext();
  const mixer = useMemo(() => {
    if (cohostEnabled && cohostVMEnabled) {
      // avoid user error, force to use default settings
      const vbgEffects = defaultCohostVBGSettings();
      updateVirtualBackgroundEffects(vbgEffects);
      return new CohostVideoMixer('720p', {
        enabled: true,
        effects: vbgEffects,
      });
    } else {
      return new PlayerVideoMixer({
        virtualBackground: { enabled: vbgEnabled, effects: vbgEffects.current },
      });
    }
  }, [
    cohostEnabled,
    cohostVMEnabled,
    updateVirtualBackgroundEffects,
    vbgEffects,
    vbgEnabled,
  ]);
  const vesStore = useMemo(() => {
    if (cohostEnabled && cohostVMEnabled) {
      return new VideoEffectsSettingsCohostStore({
        cohostSolo: defaultCohostVideoEffectsSettings('fullscreen-solo'),
        cohostInterview: defaultCohostVideoEffectsSettings(
          'fullscreen-interview'
        ),
      });
    }
    return new VideoEffectsSettingsDummyStore();
  }, [cohostEnabled, cohostVMEnabled]);

  return (
    <VenueInitProvider>
      <VenueContextProvider paramVenueId={useParamVenueId()}>
        {(venueId) => {
          return (
            <VenueBootstrap
              venueId={venueId}
              clientType={ClientType.Audience}
              providers={[<UserRequired />]}
              deviceDisabled={!!zoomEnv}
              mixer={mixer}
              vesStore={vesStore}
            >
              <ZoomVenueProvider zoomEnv={zoomEnv}>
                <Audience
                  zoomEnv={zoomEnv}
                  cohostEnabled={cohostEnabled}
                  cohostVMEnabled={cohostVMEnabled}
                />
              </ZoomVenueProvider>
            </VenueBootstrap>
          );
        }}
      </VenueContextProvider>
      <VenueInitLoading />
    </VenueInitProvider>
  );
}

function ReturnlessHooks(props: {
  venue: Venue;
  clientId: string;
  clientType: ClientType;
  isController: boolean;
  isCoordinator: boolean;
  svc: FirebaseService;
  emitter: EmitterListener<FirebaseEvents>;
  zoomEnv?: ZoomEnv;
  cohostEnabled: boolean;
}) {
  const {
    venue,
    clientId,
    clientType,
    isController,
    isCoordinator,
    svc,
    emitter,
    zoomEnv,
    cohostEnabled,
  } = props;

  const user = useUser();

  usePageTitle(user, venue);
  useInterceptNavigatorMediaSessionKeys();
  useInitOnDGameHosting();
  useUserWatcher(
    clientId,
    clientType,
    user,
    zoomEnv?.user.pic_url,
    cohostEnabled
  );
  useUpdateLoggerWithStreamSessionId();
  useInitFirebase(!!user.id && !!venue.id);
  useInitReduxSlice(clientId, venue.id);
  useInitMultipleUserInstancesWatcher(clientId, clientType);
  useEnsureUnlockedAudioContext();
  useInitSentryContext(venue.id);
  useInitStreamSession(isController);
  useInitGameSession({
    isController,
    initialSetup: false,
  });
  useInitStage();
  useEnsureTeamMemberRemovedWhenDisconnected(svc, emitter);
  useEnsureUpdateTeamInfoInLocalStorage();
  useInitVenueEvent(); // coordinator only
  useSubscribeVenueEvent();
  useFirebaseVenueSyncing(isCoordinator);
  useMarkVenueInitCompleted(!!user.id);

  return null;
}

function AudienceRTCServiceProvider(props: {
  clientId: string;
  readyToJoin: boolean;
  children?: ReactNode;
}) {
  const { clientId, readyToJoin } = props;
  const audienceRtcServiceMap = useAudienceRTCServiceMap(clientId);
  const dummyRTCServiceMap = useDummyAudienceRTCServiceMap(clientId);
  const rtcServiceMap = getFeatureQueryParam('stream-use-dummy')
    ? dummyRTCServiceMap
    : audienceRtcServiceMap;

  useInitWebRTCMVMProcessor(rtcServiceMap.stage);

  return (
    <RTCServiceContextProvider
      readyToJoin={readyToJoin}
      rtcServiceMap={rtcServiceMap}
    >
      {props.children}
    </RTCServiceContextProvider>
  );
}

function CapacityAndDeviceCheck(props: {
  venueId: string;
  maybeCohost?: boolean;
}) {
  return (
    <VenueCapacityCheck>
      <DeviceCheckModal {...props} />
    </VenueCapacityCheck>
  );
}
