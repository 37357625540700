import { type EnumsGamePackRecommendationScenario } from '@lp-lib/api-service-client/public';

import { useOndGameState } from './hooks';
import { PostGameDisplay } from './PostGame/PostGameDisplay';
import { PreGameDisplay } from './PreGame/PreGameDisplay';
import { useShowPreGame } from './PreGame/Provider';

export function PrePostGamePriority(props: {
  scenario: EnumsGamePackRecommendationScenario;
  noPostGameSurvey?: boolean;
}) {
  const showPreGame = useShowPreGame();
  const ondState = useOndGameState();

  if (showPreGame) {
    return <PreGameDisplay />;
  } else if (ondState === 'ended') {
    return (
      <PostGameDisplay
        scenario={props.scenario}
        noPostGameSurvey={props.noPostGameSurvey}
      />
    );
  } else {
    return null;
  }
}
