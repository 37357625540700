import { Sidetab, Widget } from '@typeform/embed-react';
import { useEffect, useState } from 'react';
import { useEffectOnce, useLocalStorage } from 'react-use';

import { GameSessionUtil } from '@lp-lib/game';

import { Modal } from '../../../components/common/Modal';
import {
  useGameSessionBlock,
  useGameSessionGamePackId,
  useGameSessionName,
  useGameSessionStatus,
  useIsLiveGamePlay,
} from '../../../components/Game/hooks';
import { CloseIcon } from '../../../components/icons/CloseIcon';
import { useMyOrganization } from '../../../components/Organization';
import { useIsPairingGamePlay } from '../../../components/Pairing';
import { useStreamSessionId } from '../../../components/Session';
import { useTeamCaptainParticipant } from '../../../components/TeamAPI/TeamV1';
import { isGuest, useUser } from '../../../components/UserContext';
import { useVenueEvent } from '../../../components/Venue';
import { useVenueId } from '../../../components/Venue/VenueProvider';
import config from '../../../config';
import { useInstance } from '../../../hooks/useInstance';
import { useMyInstance } from '../../../hooks/useMyInstance';
import { type TeamId } from '../../../types';

const LastScoredTeamInfoKey = 'lastScoredTeamInfo';

interface LastScoredTeamInfo {
  teamId: TeamId;
  sessionId: string;
}

const useLastScoredTeamId = (): string | null => {
  const sessionId = useStreamSessionId();

  const [lastScoredTeamInfo] = useLocalStorage<LastScoredTeamInfo>(
    LastScoredTeamInfoKey
  );

  return lastScoredTeamInfo &&
    sessionId &&
    lastScoredTeamInfo.sessionId === sessionId
    ? lastScoredTeamInfo.teamId
    : null;
};

export function useWriteLastScoredTeamId(): void {
  const [, setLastScoredTeamInfo] = useLocalStorage<LastScoredTeamInfo>(
    LastScoredTeamInfoKey
  );

  const me = useMyInstance();
  const gameSessionStatus = useGameSessionStatus();
  const gameSessionBlock = useGameSessionBlock();

  const sessionId = useStreamSessionId();
  const teamId = me?.teamId;
  const isScoreBoardShow =
    gameSessionStatus ===
    GameSessionUtil.StatusMapFor(gameSessionBlock?.type)?.scoreboard;

  useEffect(() => {
    if (!isScoreBoardShow || !sessionId || !teamId) {
      return;
    }

    setLastScoredTeamInfo({
      sessionId,
      teamId,
    });
  }, [
    gameSessionStatus,
    isScoreBoardShow,
    sessionId,
    setLastScoredTeamInfo,
    teamId,
  ]);
}

type TypeFormHiddenKeys =
  | 'organization'
  | 'streamId'
  | 'teamId'
  | 'userId'
  | 'userName'
  | 'email'
  | 'gamePackId'
  | 'gamePackName'
  | 'mode'
  | 'teamcaptain';

type TypeFormHiddenFields = {
  [key in TypeFormHiddenKeys]: string;
};

export const useHiddenFieldsSnapShot = (): TypeFormHiddenFields => {
  const organization = useMyOrganization();
  const event = useVenueEvent();
  const venueId = useVenueId();
  const sessionId = useStreamSessionId();
  const lastScoredTeamId = useLastScoredTeamId();
  const user = useUser();
  const gamePackId = useGameSessionGamePackId();
  const gameSessionName = useGameSessionName();
  const isLive = useIsLiveGamePlay();
  const isPairingGame = useIsPairingGamePlay();
  const me = useMyInstance();
  const captain = useTeamCaptainParticipant(me?.teamId);

  // Take a snapshot to prevent data from being cleaned up or changed accidentally
  return useInstance(() => ({
    organization: event?.typeformOrgName || organization?.name || '',
    orgName: organization?.name || '',
    streamId: `${venueId}/sessions/${sessionId}`,
    teamId: lastScoredTeamId || '',
    userId: user.id,
    firstName: user.organizer?.firstName || '',
    userName: user.username,
    email: !isGuest(user) ? user.email || '' : '',
    gamePackId: gamePackId || '',
    gamePackName: gamePackId ? gameSessionName || '' : '',
    mode: `${isLive ? 'LIVE' : 'OnD'}${isPairingGame ? '_Pairs' : ''}`,
    teamcaptain: captain ? `${captain.username} (${captain.id})` : '',
  }));
};

export function TypeformModal(props: {
  onShow?: () => void;
  onComplete?: () => void;
}): JSX.Element {
  useEffectOnce(() => {
    props.onShow?.();
  });

  const [ready, setReady] = useState(false);

  const isLiveGamePlay = useIsLiveGamePlay();
  const hidden = useHiddenFieldsSnapShot();
  const widget = useInstance(() => {
    const id = isLiveGamePlay
      ? config.misc.typeFormIdLive
      : config.misc.typeFormIdOnd;

    return (
      <Widget
        id={id}
        hidden={hidden}
        className='w-full h-full'
        onReady={() => {
          setReady(true);
        }}
        onSubmit={props.onComplete}
      ></Widget>
    );
  });

  const handleClose = () => {
    props.onComplete?.();
  };

  return (
    <Modal stopPropagateMouseDown borderStyle='none' bgStyle='transparent'>
      <div className='w-full h-full flex flex-col items-center'>
        <div
          className={`w-full flex flex-row-reverse p-3 ${
            ready ? 'block' : 'hidden'
          }`}
        >
          <div className='cursor-pointer' onClick={handleClose}>
            <CloseIcon></CloseIcon>
          </div>
        </div>
        <div className='w-280 h-160'>{widget}</div>
      </div>
    </Modal>
  );
}

export function TypeformSideTab(props: {
  open: boolean;
  onClose?: () => void;
  onSubmit: () => void;
}): JSX.Element {
  const hidden = useHiddenFieldsSnapShot();

  return (
    <Sidetab
      open={props.open ? 'load' : undefined}
      id={config.misc.typeFormIdOnd}
      buttonText='Rate your experience'
      buttonColor='#E84764'
      onClose={props.onClose}
      onSubmit={props.onSubmit}
      customIcon='★'
      hidden={hidden}
    />
  );
}
