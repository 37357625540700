import { useEffect, useRef } from 'react';

import { BrowserTimeoutCtrl } from '../utils/BrowserTimeoutCtrl';

// cancellable timeout. set delayMs to null to cancel the timeout.
export function useTimeout(callback: () => unknown, delayMs: number | null) {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delayMs !== null) {
      const ctrl = new BrowserTimeoutCtrl();
      ctrl.set(() => callbackRef.current(), delayMs);
      return () => ctrl.clear();
    }
  }, [delayMs]);
}
