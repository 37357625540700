import { type CSSProperties, type ReactNode, useEffect, useState } from 'react';

import {
  assertExhaustive,
  type HeadToHeadBlock,
  HeadToHeadBlockGameSessionStatus,
} from '@lp-lib/game';

import { useLiveAsyncCall } from '../../../../hooks/useAsyncCall';
import { useTimeout } from '../../../../hooks/useTimeout';
import { TimeUtils } from '../../../../utils/time';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { CardsIcon } from '../../../icons/CardsIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import { Loading } from '../../../Loading';
import { useGameSessionStatus, useOndWaitMode } from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { type CoordinatorControllerProps } from '../Common/CoordinatorController/CoordinatorController';
import { AutoProgressingIndicator } from '../Common/GamePlay/AutoProgressingIndicator';
import {
  useCurrentHeadToHeadGameCard,
  useHeadToHeadGamePlayAPI,
  useHeadToHeadGameProgress,
  useNextRoundPhaseAfterPlaying,
} from './HeadToHeadBlockProvider';
import { HeadToHeadUtils } from './utils';

function Container(props: { children?: ReactNode }) {
  return (
    <div className='flex flex-col items-center gap-2.5'>{props.children}</div>
  );
}

function ReadyButton(_props: { block: HeadToHeadBlock }) {
  const api = useHeadToHeadGamePlayAPI();

  const handleClick = () => {
    api.revealCurrentCard();
  };

  return (
    <button
      type='button'
      className='w-full h-10 bg-lp-green-003 rounded-xl text-sms font-medium text-white'
      onClick={handleClick}
    >
      Ready?
    </button>
  );
}

function EndRoundButton(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const api = useHeadToHeadGamePlayAPI();
  const judgingEnabled = HeadToHeadUtils.JudgingEnabled(block);
  const nextPhase = useNextRoundPhaseAfterPlaying(block);

  const onClick = async () => {
    if (nextPhase) {
      await api.setRoundPhase(nextPhase, 'coordinator end round');
      return;
    }
    await ondWaitEnd();
  };

  return (
    <button
      type='button'
      className={`btn-secondary w-full h-10 flex items-center justify-center gap-2.5 text-sms font-medium truncate`}
      onClick={onClick}
    >
      <DoubleRightArrow className='w-4 h-4 fill-current' />
      {`End & ${judgingEnabled ? 'Distribute Points' : 'Move On'}`}
    </button>
  );
}

function NextButton(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const api = useHeadToHeadGamePlayAPI();
  const progress = useHeadToHeadGameProgress();
  const turnsMode = HeadToHeadUtils.TurnsMode(block);

  const handleClick = () => {
    api.nextCard();
  };

  const btnCardAction =
    'w-full h-10 rounded-xl flex items-center justify-center text-sms font-medium gap-2.5 text-white';

  if (turnsMode) {
    return (
      <button
        type='button'
        className={`${btnCardAction} bg-lp-green-003`}
        onClick={handleClick}
      >
        <DoubleRightArrow className='w-5 h-5 fill-current' />
        <div>{`Go to ${
          progress.currentTurn === 'groupA' ? 'Blue' : 'Pink'
        } Team`}</div>
      </button>
    );
  }

  return (
    <button
      type='button'
      className={`${btnCardAction} bg-layer-002 border border-secondary`}
      style={{
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.25)',
      }}
      onClick={handleClick}
    >
      <CardsIcon className='w-5 h-5 fill-current' />
      <div>Shuffle Cards</div>
    </button>
  );
}

function PlayingPhase(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const progress = useHeadToHeadGameProgress();
  const card = useCurrentHeadToHeadGameCard();

  switch (progress.roundPhase) {
    case 'playing':
      if (progress.currentCardPhase === 'ready') {
        return (
          <Container>
            <ReadyButton block={block} />
          </Container>
        );
      }
      return (
        <Container>
          <EndRoundButton block={block} />
          {card && <NextButton block={block} />}
          {block.fields.gameTimeSec > 0 && (
            <AutoProgressingIndicator
              formatText={(remainingSec) => (
                <>
                  <TimerIcon />
                  {`Time: ${TimeUtils.DurationFormattedHHMMSS(
                    remainingSec * 1000
                  )}`}
                </>
              )}
            />
          )}
        </Container>
      );
    default:
      break;
  }
}

function EndGameJudgingPhase(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const api = useHeadToHeadGamePlayAPI();
  const [show, setShow] = useState(false);

  // avoid clicking the same area too fast
  // wait the judging animation
  useTimeout(() => setShow(true), 3000);

  const onClick = () => {
    api.setRoundPhase('reveal-winner', 'coordinator-end-judging');
  };

  if (!show) return null;

  return (
    <Container>
      <div className='w-full h-10 relative'>
        <div
          className={`absolute bg-blue-001 inset-0 rounded-xl transform animate-plusing`}
          style={
            {
              '--tw-plusing-duration': '2s',
            } as CSSProperties
          }
        />
        <button
          type='button'
          className={`btn-primary w-full h-full absolute inset-0 flex items-center justify-center gap-2.5 text-sms font-medium truncate`}
          onClick={onClick}
        >
          <DoubleRightArrow className='w-4 h-4 fill-current' />
          {`End Judging`}
        </button>
      </div>
      {block.fields.gameTimeSec > 0 && (
        <AutoProgressingIndicator
          formatText={(remainingSec) => (
            <>
              <TimerIcon />
              {`Time: ${TimeUtils.DurationFormattedHHMMSS(
                remainingSec * 1000
              )}`}
            </>
          )}
        />
      )}
    </Container>
  );
}

function useAutoProgressToShowResults(key: string, schedule: boolean) {
  const mode = useOndWaitMode();
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    setTriggered(false);
  }, [key]);

  useEffect(() => {
    if (!schedule || mode !== 'wait' || triggered) return;
    setTriggered(true);
    ondWaitEnd();
  }, [schedule, mode, triggered]);
}

function RevealWinnerPhase(props: {
  block: HeadToHeadBlock;
  ended: boolean;
  setAutoProgress: (val: boolean) => void;
}) {
  const { block, ended } = props;
  const api = useHeadToHeadGamePlayAPI();
  const replayable = block.fields.replayable && !ended;
  const [clicked, setClicked] = useState(false);

  const {
    call: onClick,
    state: { state },
  } = useLiveAsyncCall(async () => {
    setClicked(true);
    if (replayable) {
      await api.setRoundPhase('select-next', 'coordinator-clicked-move-on');
    } else {
      await ondWaitEnd();
      props.setAutoProgress(!ended);
    }
  });

  if (clicked) return null;

  return (
    <Container>
      <button
        type='button'
        className={`btn-primary w-full h-10 flex items-center justify-center gap-2.5 text-sms font-medium truncate`}
        onClick={onClick}
        disabled={state.isRunning}
      >
        <DoubleRightArrow className='w-4 h-4 fill-current' />
        {`Move On`}
      </button>
      {block.fields.gameTimeSec > 0 && (
        <AutoProgressingIndicator
          formatText={(remainingSec) => (
            <>
              <TimerIcon />
              {`Time: ${TimeUtils.DurationFormattedHHMMSS(
                remainingSec * 1000
              )}`}
            </>
          )}
        />
      )}
    </Container>
  );
}

function SelectNextGroupPhase(props: {
  block: HeadToHeadBlock;
  ended: boolean;
  setAutoProgress: (val: boolean) => void;
}) {
  const { block, ended } = props;
  const [clicked, setClicked] = useState(false);

  const {
    call: onClick,
    state: { state },
  } = useLiveAsyncCall(async () => {
    setClicked(true);
    await ondWaitEnd();
    props.setAutoProgress(!ended);
  });

  if (clicked) return null;

  return (
    <Container>
      <button
        type='button'
        className={`btn-secondary w-full h-10 flex items-center justify-center gap-2.5 text-sms font-medium truncate`}
        onClick={onClick}
        disabled={state.isRunning}
      >
        {state.isRunning ? (
          <Loading text='' imgClassName='w-4 h-4' />
        ) : (
          <DoubleRightArrow className='w-4 h-4 fill-current' />
        )}
        {`Finish ${block.fields.gameName || 'Game'}`}
      </button>
      {block.fields.gameTimeSec > 0 && (
        <AutoProgressingIndicator
          formatText={(remainingSec) => (
            <>
              <TimerIcon />
              {`Time: ${TimeUtils.DurationFormattedHHMMSS(
                remainingSec * 1000
              )}`}
            </>
          )}
        />
      )}
    </Container>
  );
}

export function HeadToHeadBlockCoordinatorController(
  props: CoordinatorControllerProps<HeadToHeadBlock>
): JSX.Element | null {
  const block = props.selectedBlock;
  const gss = useGameSessionStatus<HeadToHeadBlockGameSessionStatus>();
  const progress = useHeadToHeadGameProgress();

  const [autoProgress, setAutoProgress] = useState(false);

  useEffect(() => {
    setAutoProgress(false);
  }, [block.id]);

  useAutoProgressToShowResults(block.id, autoProgress);

  const gameEnded = gss === HeadToHeadBlockGameSessionStatus.GAME_END;

  switch (gss) {
    case HeadToHeadBlockGameSessionStatus.LOADED:
    case HeadToHeadBlockGameSessionStatus.GAME_INIT:
    case HeadToHeadBlockGameSessionStatus.GAME_INTRO:
      return null;
    case HeadToHeadBlockGameSessionStatus.GAME_START:
    case HeadToHeadBlockGameSessionStatus.GAME_END:
      switch (progress.roundPhase) {
        case 'playing':
          return <PlayingPhase block={block} />;
        case 'judging-after-playing':
          return <EndGameJudgingPhase block={block} />;
        case 'reveal-winner':
          return (
            <RevealWinnerPhase
              block={block}
              ended={gameEnded}
              setAutoProgress={setAutoProgress}
            />
          );
        case 'select-next':
          return (
            <SelectNextGroupPhase
              block={block}
              ended={gameEnded}
              setAutoProgress={setAutoProgress}
            />
          );
        default:
          assertExhaustive(progress.roundPhase);
          return null;
      }
    case HeadToHeadBlockGameSessionStatus.RESULTS:
    case HeadToHeadBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
