import { NotificationCenter as ConfigurableNotificationCenter } from '../../../components/Notification';
import { useIsTeamsOnTop } from '../../../components/TeamAPI/TeamV1';

export function NotificationCenter(): JSX.Element {
  const teamsOnTop = useIsTeamsOnTop();
  return (
    <ConfigurableNotificationCenter
      className='min-w-160 flex flex-col items-center justify-center'
      toastClassName='relative mb-2'
      position={teamsOnTop ? 'bottom-center' : 'top-center'}
      transition='lp'
      newestOnTop
      autoClose={false}
      limits={3}
    />
  );
}
