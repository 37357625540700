import { TimeUtils } from '../../../../../utils/time';
import { useOndWaitModeInfo } from '../../../hooks';
import { ondWaitExtendDuration } from '../../../OndPhaseRunner/OndPhaseRunner';

export function AutoProgressingIndicator(props: {
  formatText?: (remainingSec: number) => React.ReactNode;
  className?: string;
}) {
  const { remainingSec } = useOndWaitModeInfo();

  const handleIncrOneMin = () => {
    ondWaitExtendDuration(60);
  };

  if (remainingSec === Infinity || remainingSec < 0) return null;

  return (
    <div
      className={`w-full rounded-xl bg-secondary px-3 h-10 flex justify-between items-center ${props.className} gap-1.5`}
    >
      <div className='flex-1 flex items-center gap-1 text-xs text-tertiary font-bold tabular-nums'>
        {props.formatText ? (
          props.formatText(remainingSec)
        ) : (
          <>
            Progressing in{' '}
            {TimeUtils.DurationFormattedHHMMSS(remainingSec * 1000)}
          </>
        )}
      </div>
      <button
        type='button'
        className='flex-none btn-primary px-2 py-1.5 text-2xs font-bold'
        onClick={handleIncrOneMin}
      >
        +1 min
      </button>
    </div>
  );
}
