import {
  assertExhaustive,
  type JeopardyBlock,
  JeopardyBlockGameSessionStatus,
} from '@lp-lib/game';

import { useGameSessionStatus } from '../../hooks';
import { type CoordinatorControllerProps } from '../Common/CoordinatorController/CoordinatorController';
import { useStableBlock } from '../Common/hooks';

function Controls(_props: { block: JeopardyBlock }): JSX.Element | null {
  return null;
}

export function JeopardyBlockCoordinatorController(
  props: CoordinatorControllerProps<JeopardyBlock>
): JSX.Element | null {
  const block = useStableBlock(props.selectedBlock);
  const gss = useGameSessionStatus<JeopardyBlockGameSessionStatus>();

  switch (gss) {
    case JeopardyBlockGameSessionStatus.GAME_INIT:
    case JeopardyBlockGameSessionStatus.GAME_START:
    case JeopardyBlockGameSessionStatus.GAME_END:
    case JeopardyBlockGameSessionStatus.RESULTS:
    case JeopardyBlockGameSessionStatus.OUTRO:
      return <Controls block={block} />;
    case JeopardyBlockGameSessionStatus.LOADED:
    case JeopardyBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
