import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEffectOnce, useLocalStorage, usePreviousDistinct } from 'react-use';
import useSWRImmutable from 'swr/immutable';

import { useExperienceScoreAPI } from '../../../components/ExperienceScore';
import {
  useIsLiveGamePlay,
  useOndGameState,
} from '../../../components/Game/hooks';
import { useShowPreGame } from '../../../components/Game/PreGame/Provider';
import { useLobbyAPI } from '../../../components/Lobby';
import {
  useIsPairingGameAllCompleted,
  usePairing,
} from '../../../components/Pairing';
import {
  useIsStreamSessionEnded,
  useStreamSession,
  useStreamSessionId,
} from '../../../components/Session';
import config from '../../../config';
import { useFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useMyInstance } from '../../../hooks/useMyInstance';
import { apiService } from '../../../services/api-service';
import { type NpsSettings } from '../../../types';
import { PairingMedalModal } from './PairingMedal';
import {
  TypeformModal,
  TypeformSideTab,
  useWriteLastScoredTeamId,
} from './Typeform';
import { log } from './utils';

type TypeformDisabledReason = 'Zoom';

function usePairingMedalLastShowedPairingId() {
  return useLocalStorage<string>('lastPairingMedal', '', {
    raw: true,
  });
}

function useUserNpsSettings() {
  const { data, error, isLoading, mutate } = useSWRImmutable(
    '/users/settings/nps',
    async () => {
      const resp = await apiService.userSettings.getMyNpsSettings();
      return resp.data.nps ?? null;
    },
    {
      shouldRetryOnError: false,
    }
  );

  const update = useLiveCallback(
    async (updatedSettings: Partial<NpsSettings>) => {
      const resp = await apiService.userSettings.updateMyNpsSettings(
        updatedSettings
      );
      const nps = resp.data.nps ?? null;
      mutate(nps);
      return nps;
    }
  );

  return {
    npsSettings: data,
    isLoading,
    error,
    update,
  };
}

function OffBoardingSteps(props: {
  npsSettings: ReturnType<typeof useUserNpsSettings>['npsSettings'];
  updateNpsSettings: ReturnType<typeof useUserNpsSettings>['update'];
  typeformDisabledReason?: TypeformDisabledReason;
}) {
  const { updateNpsSettings } = props;
  const [step, setStep] = useState(0);
  const next = useCallback(() => setStep((prev) => prev + 1), []);

  const isLiveGamePlay = useIsLiveGamePlay();
  const pairing = usePairing();
  const isPairingGameAllCompleted = useIsPairingGameAllCompleted();
  const [pairingMedalLastShowedPairingId, setPairingMedalLastShowedPairingId] =
    usePairingMedalLastShowedPairingId();
  const showPreGame = useShowPreGame();

  const showPairingMedal = useInstance(
    () =>
      pairing &&
      isPairingGameAllCompleted &&
      pairingMedalLastShowedPairingId !== pairing.pairingId
  );
  const sessionId = useStreamSessionId();

  const showTypeform = useInstance(() => {
    if (!!props.typeformDisabledReason) {
      log.info(`suppress typeform in ${props.typeformDisabledReason}`);
      return 'disabled';
    }

    // live
    if (isLiveGamePlay) {
      log.info('show typeform in Live');
      return 'enabled';
    }

    return 'disabled';
  });

  const components = useInstance(() => {
    const res = [];

    if (showPairingMedal && pairing?.mainGamePack) {
      res.push(
        <PairingMedalModal
          pack={pairing.mainGamePack}
          onShow={() => {
            setPairingMedalLastShowedPairingId(pairing.pairingId);
          }}
          onComplete={next}
        />
      );
    }

    // it can only be enabled in live game play
    if (showTypeform === 'enabled' && isLiveGamePlay) {
      res.push(<TypeformModal onComplete={next} />);
    }

    if (!isLiveGamePlay && showTypeform !== 'disabled') {
      res.push(
        <TypeformSideTab
          open={showTypeform === 'enabled'}
          onSubmit={async () => {
            next();
            try {
              await updateNpsSettings({
                ondAnsweredSessionId: sessionId ?? null,
              });
            } catch (error) {
              log.error('failed to update nps settings', error);
            }
          }}
        />
      );
    }

    return res;
  });

  if ((!isLiveGamePlay && showPreGame) || step >= components.length)
    return null;
  return components[step];
}

function ExperienceLogEmitter() {
  const api = useExperienceScoreAPI();
  useEffectOnce(() => {
    // Flush the log and reset the score. This should only happen at the end of
    // a game.
    api.emitLog();
    api.reset();
  });
  return null;
}

const useIsOffBoarding = (): boolean => {
  const isLive = useIsLiveGamePlay();
  const ondState = useOndGameState();
  const lastOndState = usePreviousDistinct(ondState);
  const [isSessionEnded, endedAt] = useIsStreamSessionEnded();
  const me = useMyInstance();
  const ignoreReset = useFeatureQueryParam('offboarding-ignore-reset');

  return useMemo(() => {
    if (!config.misc.offBoardingEnabled) return false;
    if (!isSessionEnded) return false;
    if (!me?.joinedAt || me.joinedAt > endedAt) return false;
    if (ignoreReset && !isLive && lastOndState !== 'ended') return false;

    return true;
  }, [
    endedAt,
    ignoreReset,
    isLive,
    isSessionEnded,
    lastOndState,
    me?.joinedAt,
  ]);
};

export function OffBoarding(props: {
  typeformDisabledReason?: TypeformDisabledReason;
}): JSX.Element | null {
  useWriteLastScoredTeamId();

  const isOffBoarding = useIsOffBoarding();
  const lobbyAPI = useLobbyAPI();
  const session = useStreamSession();
  // fetch the nps settings upfront once
  const {
    npsSettings,
    isLoading,
    update: updateNpsSettings,
  } = useUserNpsSettings();

  useEffect(() => {
    if (!isOffBoarding || !session?.id) return;
    lobbyAPI.signalOffBoarding(session.id);
  }, [isOffBoarding, lobbyAPI, session?.id]);

  if (!isOffBoarding || isLoading) {
    return null;
  }

  return (
    <>
      <ExperienceLogEmitter />
      <OffBoardingSteps
        npsSettings={npsSettings}
        updateNpsSettings={updateNpsSettings}
        typeformDisabledReason={props.typeformDisabledReason}
      />
    </>
  );
}
