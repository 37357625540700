import { useCallback } from 'react';
import { useEffectOnce } from 'react-use';
import useSWR from 'swr';
import { useSnapshot } from 'valtio';

import { EnumsBlockType } from '@lp-lib/api-service-client/public';
import {
  type Block,
  BlockType,
  fromAPIBlock,
  type InstructionCard,
} from '@lp-lib/game';

import { usePreGameAnalytics } from '../../../analytics/preGame';
import { useIsCoordinator, useMyInstance } from '../../../hooks/useMyInstance';
import { apiService } from '../../../services/api-service';
import { getStaticAssetPath } from '../../../utils/assets';
import { Loading } from '../../Loading';
import {
  useOndGameUIControl,
  useOndGameUIControlState,
} from '../../OnDGameUIControl';
import { useTownhallEnabled } from '../../Townhall';
import { useVenueDerivedSettings, useVenueId } from '../../Venue';
import {
  HostedTutorialAccessory,
  InstructionBlockCards,
  InstructionBlockMainPanel,
} from '../Blocks/Instruction/InstructionBlockMainPanel';
import { InstructionBlockPlaygroundLayout } from '../Blocks/Instruction/InstructionBlockPlagroundLayout';
import {
  InstructionBlockRightPanel,
  InstructionBlockTeams,
} from '../Blocks/Instruction/InstructionBlockRightPanel';
import { PreGameReadyUpWidget } from './PreGameReadyUpWidget';
import { usePreGameSharedAPI } from './Provider';

function RandomizeButton(): JSX.Element | null {
  const isCoordinator = useIsCoordinator();
  const uiCtrl = useOndGameUIControl();
  const { showReRandomizeButton } = useOndGameUIControlState();
  const analytics = usePreGameAnalytics();

  const onClickReRandomize = () => {
    analytics?.trackTeamsReRandomized();
    uiCtrl?.onClickReRandomizeTeams();
  };

  if (!isCoordinator || !showReRandomizeButton) return null;

  return (
    <button
      type='button'
      className='btn btn-secondary w-max px-4 py-2 tracking-wide'
      onClick={onClickReRandomize}
    >
      Reshuffle Teams
    </button>
  );
}

function useCancelPreGame(): (() => void) | undefined {
  const isCoordinator = useIsCoordinator();
  const uiCtrl = useOndGameUIControl();
  const analytics = usePreGameAnalytics();

  const onClickCancel = useCallback(() => {
    analytics?.trackCancelClicked();
    uiCtrl?.onClickCancelPreGame();
  }, [analytics, uiCtrl]);

  if (!isCoordinator || !uiCtrl?.onClickCancelPreGame) return undefined;
  return onClickCancel;
}

function CancelButton(): JSX.Element | null {
  const onClickCancel = useCancelPreGame();

  if (!onClickCancel) return null;

  return (
    <button
      type='button'
      className='btn text-xs text-icon-gray'
      onClick={onClickCancel}
    >
      Cancel
    </button>
  );
}

const teamAvatars1 = getStaticAssetPath('images/team-avatars-1.png');
const teamAvatars2 = getStaticAssetPath('images/team-avatars-2.png');
const teamAvatars3 = getStaticAssetPath('images/team-avatars-3.png');

function FallbackInstructions(): JSX.Element {
  const preferSinglePlayerTeams =
    useVenueDerivedSettings()?.preferSinglePlayerTeams;
  return (
    <div className='w-full h-full flex flex-col items-center justify-center gap-15 vh-sm:gap-8'>
      <div className='w-full flex items-center justify-center gap-10 px-10'>
        <div className='flex-1 max-w-40'>
          <img src={teamAvatars1} className='w-full' alt='Team 1' />
        </div>
        <div className='flex-1 max-w-40'>
          <img src={teamAvatars2} className='w-full' alt='Team 2' />
        </div>
        <div className='flex-1 max-w-40'>
          <img src={teamAvatars3} className='w-full' alt='Team 3' />
        </div>
      </div>
      <div className='flex flex-col items-center gap-6'>
        {preferSinglePlayerTeams ? (
          <div className='text-3.5xl vh-sm:text-2xl font-bold'>
            Here is your group! {'->'}
          </div>
        ) : (
          <>
            <div className='text-3.5xl vh-sm:text-2xl font-bold'>
              Here are your teams! {'->'}
            </div>
            <RandomizeButton />
          </>
        )}
      </div>
    </div>
  );
}

function useBlock(blockId?: Block['id'] | null) {
  return useSWR(
    blockId ? [`/api/blocks/`, blockId] : null,
    async ([, blockId]) => {
      const resp = await apiService.block.getBlock(blockId);
      return resp.data.block;
    }
  );
}

function NoTownHallController(): JSX.Element | null {
  const townhallEnabled = useTownhallEnabled();
  if (townhallEnabled) return null;

  return <PreGameReadyUpWidget />;
}

export function PreGameDisplay(): JSX.Element | null {
  const shared = usePreGameSharedAPI();
  const state = useSnapshot(shared.state);
  const me = useMyInstance();
  const analytics = usePreGameAnalytics();
  const { data, isLoading } = useBlock(state.shared?.blockId);
  const onClickCancel = useCancelPreGame();
  const venueId = useVenueId();

  useEffectOnce(() => {
    analytics.trackPreGameViewed({ venueId });
  });

  const onClickCard = (_card: InstructionCard, index: number) => {
    if (!data) return;
    analytics?.trackInstructionClicked({
      instructionBlockId: data.id,
      cardIndex: index,
    });
  };

  if (state.stage !== 'present') return null;

  const hasInstructionBlock =
    data && data.type === EnumsBlockType.BlockTypeInstruction;

  const title =
    isLoading || !hasInstructionBlock ? null : state.shared?.brandName ? (
      <>
        How to Play{' '}
        <span className='text-tertiary'>{state.shared?.brandName}</span>
      </>
    ) : (
      'How to Play'
    );

  const hostedTutorialAccessory = (
    <HostedTutorialAccessory hidden={!state.shared?.hasHostedTutorial} />
  );

  return (
    <div className={`fixed w-screen h-screen text-white animate-fade-in`}>
      <InstructionBlockPlaygroundLayout>
        <InstructionBlockMainPanel
          title={title}
          leftAccessory={<CancelButton />}
          rightAccessory={hostedTutorialAccessory}
          onCancel={onClickCancel}
        >
          {isLoading ? (
            <div className='flex items-center justify-center w-full h-full'>
              <Loading text='' />
            </div>
          ) : hasInstructionBlock ? (
            <InstructionBlockCards
              block={fromAPIBlock(data, BlockType.INSTRUCTION)}
              onClickCard={onClickCard}
              hostedTutorialAccessory={hostedTutorialAccessory}
            />
          ) : (
            <FallbackInstructions />
          )}
        </InstructionBlockMainPanel>
        <InstructionBlockRightPanel>
          <div className='w-full h-full flex flex-col gap-4'>
            <NoTownHallController />
            <div className='flex-1 min-h-0'>
              <InstructionBlockTeams teamId={me?.teamId} />
            </div>
          </div>
        </InstructionBlockRightPanel>
      </InstructionBlockPlaygroundLayout>
    </div>
  );
}
