import { useEffect, useRef, useState } from 'react';

import {
  assertExhaustive,
  type InstructionBlock,
  InstructionBlockGameSessionStatus,
} from '@lp-lib/game';

import { useInstructionBlockAnalytics } from '../../../../analytics/instructionBlock';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { BrowserTimeoutCtrl } from '../../../../utils/BrowserTimeoutCtrl';
import { StorageStore } from '../../../../utils/storage';
import { glAppend } from '../../../GameLog/GameLogComponents';
import { Loading } from '../../../Loading';
import { useAmICohost } from '../../../Player';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useOndStateBlockHasHostedTutorial,
} from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { type CoordinatorControllerProps } from '../Common/CoordinatorController/CoordinatorController';
import { AutoProgressingIndicator } from '../Common/GamePlay/AutoProgressingIndicator';

export function EveryonesReadyButton(props: {
  disabled: boolean;
  onClick: (includeHostedTutorial: boolean) => void;
  hasHostedTutorial: boolean;
  animation?: string;
  children?: React.ReactNode;
}): JSX.Element | null {
  const { animation, children, disabled, onClick, hasHostedTutorial } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [storage] = useState(
    () => new StorageStore<{ include: boolean }>('hostedTutorial')
  );

  const handleClick = () => {
    onClick(inputRef.current?.checked ?? false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    storage.set('include', e.target.checked);
  };

  return (
    <div className='w-full'>
      <div className='relative w-full h-10'>
        <div
          className={`
            ${disabled ? 'hidden' : ''}
            w-full h-full
            absolute
            bg-blue-001 rounded-xl
            pointer-events-none
            transform
            ${animation ?? 'animatejit-[2s_ease_1s_infinite_plusing]'}
          `}
        />
        <button
          type='button'
          className='w-full h-full absolute btn-primary flex items-center justify-center gap-2'
          onClick={handleClick}
          disabled={disabled}
        >
          {children}
        </button>
      </div>
      {hasHostedTutorial && (
        <div
          className={`
           bg-black rounded-bl-lg rounded-br-lg
            text-xs font-medium text-white tracking-wide
            px-1 py-2 mx-2
            flex items-center justify-center gap-1
          `}
        >
          <input
            ref={inputRef}
            type='checkbox'
            className='checkbox-dark'
            defaultChecked={storage.get('include') ?? true}
            onChange={handleChange}
          />
          Include Hosted Tutorial
        </div>
      )}
    </div>
  );
}

function EveryonesReady(): JSX.Element | null {
  const analytics = useInstructionBlockAnalytics();
  const time = useGameSessionLocalTimer();
  const [triggering, setTriggering] = useState(false);
  const hasHostedTutorial = useOndStateBlockHasHostedTutorial();
  const amICohost = useAmICohost();

  const onClick = useLiveCallback(async (includeHostedTutorial: boolean) => {
    if (triggering) return;
    setTriggering(true);
    analytics.trackEveryonesReadyClicked({ includeHostedTutorial });
    await glAppend('ond-user-clicked-everybodys-ready', {});
    await ondWaitEnd({ skipHostedTutorial: !includeHostedTutorial });
  });

  useEffect(() => {
    if (!triggering) return;
    // when using cloud hosting, ondWaitEnd() is not immediate. this will keep the spinner spinning, but in case of
    // some error, prevent the button from being disabled forever.
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(() => setTriggering(false), 5000);
    return () => ctrl.clear();
  }, [triggering]);

  const disabled = triggering;
  const showAutoProgressing =
    amICohost || (time !== null && time > 0 && time <= 30);

  return (
    <div className='w-full'>
      <EveryonesReadyButton
        disabled={disabled}
        onClick={onClick}
        hasHostedTutorial={hasHostedTutorial}
      >
        {disabled ? (
          <Loading text='' imgClassName='w-5 h-5' />
        ) : (
          <>Everyone’s Ready!</>
        )}
      </EveryonesReadyButton>
      {showAutoProgressing && (
        <AutoProgressingIndicator className='mt-2.5 animate-fade-in-up' />
      )}
    </div>
  );
}

export function InstructionBlockCoordinatorController(
  _props: CoordinatorControllerProps<InstructionBlock>
): JSX.Element | null {
  const gss = useGameSessionStatus<InstructionBlockGameSessionStatus>();

  switch (gss) {
    case InstructionBlockGameSessionStatus.LOADED:
      break;
    case InstructionBlockGameSessionStatus.GAME_INIT:
      break;
    case InstructionBlockGameSessionStatus.GAME_START:
    case InstructionBlockGameSessionStatus.GAME_END:
      return <EveryonesReady />;
    case InstructionBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
