import { proxy } from 'valtio';

import { EnumsUserVesProfileKey } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { required } from '../../utils/common';
import { ValtioUtils } from '../../utils/valtio';
import {
  type VideoEffectsSettings,
  type VideoEffectsSettingsStorage,
} from '../VideoEffectsSettings/types';
import { VideoEffectsSettingsUtils } from '../VideoEffectsSettings/VideoEffectsSettingsUtils';

type State = {
  [keyof in EnumsUserVesProfileKey]: VideoEffectsSettings;
};

const validKeys = Object.values(EnumsUserVesProfileKey);

export function assertValidKeyFatal(
  value: string,
  context = ''
): asserts value is EnumsUserVesProfileKey {
  if (!validKeys.includes(value as never)) {
    throw new Error(`Invalid key ${value} in ${context}`);
  }
}

export class VideoEffectsSettingsCohostStore
  implements VideoEffectsSettingsStorage
{
  private s;

  constructor(
    defaultState: State,
    private deps = {
      updateSettings: apiService.userSettings.updateVesSettings.bind(
        apiService.userSettings
      ),
      loadSettings: apiService.userSettings.getVesSettings.bind(
        apiService.userSettings
      ),
    }
  ) {
    this.s = proxy(defaultState);
  }

  getSettings(key: string): Readonly<VideoEffectsSettings> {
    assertValidKeyFatal(key);
    return ValtioUtils.detachCopy(this.s[key]);
  }

  async replaceFromExternal(key: string, ves: VideoEffectsSettings) {
    assertValidKeyFatal(key);
    this.s[key] = ves;
    await this.persist();
  }

  toggleEffect(
    key: string,
    effect: 'greenScreen' | 'podium' | 'intro' | 'outro',
    enabled: boolean
  ): void {
    assertValidKeyFatal(key);
    const setting = this.s[key][effect];
    if (!setting) return;
    setting.enabled = enabled;
    this.persist();
  }

  async load() {
    const resp = await this.deps.loadSettings();
    if (!resp.data.ves?.value) return;
    if (resp.data.ves.value.cohostSolo) {
      this.s.cohostSolo = required(
        VideoEffectsSettingsUtils.FromUser(resp.data.ves.value.cohostSolo)
      );
    }
    if (resp.data.ves.value.cohostInterview) {
      this.s.cohostInterview = required(
        VideoEffectsSettingsUtils.FromUser(resp.data.ves.value.cohostInterview)
      );
    }
  }

  async persist() {
    const settings = {
      cohostSolo: VideoEffectsSettingsUtils.ToUser(
        ValtioUtils.detachCopy(this.s.cohostSolo)
      ),
      cohostInterview: VideoEffectsSettingsUtils.ToUser(
        ValtioUtils.detachCopy(this.s.cohostInterview)
      ),
    };
    this.deps.updateSettings(settings);
  }
}
