import { useMemo } from 'react';

import {
  BlockType,
  type TitleBlockV2,
  TitleBlockV2GameSessionStatus,
} from '@lp-lib/game';

import { useAmICohost } from '../../../Player';
import {
  useGameSessionStatus,
  useOndGameCurrentPlaybackItem,
} from '../../hooks';
import { usePlaybackInfoExtra } from '../../Playback/PlaybackInfoProvider';
import { type CoordinatorControllerProps } from '../Common/CoordinatorController/CoordinatorController';
import { useStableBlock } from '../Common/hooks';
import { TitleV2Utils } from './utils';

function Controls(props: {
  block: TitleBlockV2;
  currentCard: number;
}): JSX.Element | null {
  const extra = usePlaybackInfoExtra();
  const pbi = useOndGameCurrentPlaybackItem();
  const block = pbi?.block;

  const cardCount = useMemo(() => {
    if (!block || block.type !== BlockType.TITLE_V2) return 0;
    return TitleV2Utils.GetRuntimeCardCount(block as TitleBlockV2, extra);
  }, [block, extra]);

  if (!block || block.type !== BlockType.TITLE_V2) return null;

  return (
    <div className='w-full bg-secondary rounded-xl px-4 py-3 space-y-0.5'>
      <div className='text-secondary text-3xs uppercase tracking-wide font-bold'>
        Title Cards
      </div>
      <div className='text-white text-sm font-bold tabular-nums'>
        Card {props.currentCard} of {cardCount}
      </div>
    </div>
  );
}

export function TitleBlockV2CoordinatorController(
  props: CoordinatorControllerProps<TitleBlockV2>
): JSX.Element | null {
  const block = useStableBlock(props.selectedBlock);
  const gss = useGameSessionStatus<TitleBlockV2GameSessionStatus>();
  const amICohost = useAmICohost();

  if (
    !amICohost ||
    gss === null ||
    gss === undefined ||
    gss < TitleBlockV2GameSessionStatus.PRESENT_START ||
    gss > TitleBlockV2GameSessionStatus.PRESENT_END
  ) {
    return null;
  }

  return <Controls block={block} currentCard={gss} />;
}
