import { useLayoutEffect, useRef, useState } from 'react';
import { useSnapshot } from 'valtio';

import { usePreGameAnalytics } from '../../../analytics/preGame';
import { useIsCoordinator } from '../../../hooks/useMyInstance';
import { useTimeout } from '../../../hooks/useTimeout';
import { BrowserTimeoutCtrl } from '../../../utils/BrowserTimeoutCtrl';
import { err2s } from '../../../utils/common';
import { Loading } from '../../Loading';
import {
  useOndGameUIControl,
  useOndGameUIControlState,
} from '../../OnDGameUIControl';
import { EveryonesReadyButton } from '../Blocks/Instruction/InstructionBlockCoordinatorController';
import { usePreGameSharedAPI } from './Provider';
import { showPreGame } from './utils';

// This should probably be slightly abstracted to be used elsewhere, since it's
// more complex than a typical debounce or throttle. It "holds" the value at
// `true` based on the second parameter.
function useThrottledDisabled(
  value: boolean,
  allowChanges: boolean,
  ms: number
) {
  // Always start off disabled!
  const [disabled, setDisabled] = useState(true);

  const ref = useRef(disabled);
  useLayoutEffect(() => {
    if (!allowChanges) {
      // this indicates a "reset", in a sense. Put the default state back to
      // hidden/disabled. This prevents the button from initially appearing as
      // "enabled" after seeing the post-game screen.
      setDisabled(true);
      return;
    }

    const timeout = new BrowserTimeoutCtrl();
    const debounceMs = ms;
    ref.current = value;

    if (value) {
      // Always immediately disable
      setDisabled(value);
    } else {
      // only set to `true` after it stabilizes to prevent a flickering
      // "loading" state
      timeout.set(() => setDisabled(ref.current), debounceMs);
    }

    return () => timeout.clear();
  }, [value, allowChanges, ms]);

  return disabled;
}

const DEFAULT_DELAY_MS = 120000;

export function PreGameReadyUpWidget(): JSX.Element | null {
  const isCoordinator = useIsCoordinator();
  const ctrl = useOndGameUIControl();
  const { actionDisabled, commandChannelError } = useOndGameUIControlState();
  const preGameState = usePreGameSharedAPI().state;
  const state = useSnapshot(preGameState);
  const analytics = usePreGameAnalytics();

  const handleClick = (includeHostedTutorial: boolean) => {
    analytics?.trackEveryonesReadyClicked({
      includeHostedTutorial,
    });
    ctrl?.onClickEveryoneIsReady(!includeHostedTutorial);
  };

  // Prevent UI bouncing
  const disabled = useThrottledDisabled(
    Boolean(actionDisabled),
    showPreGame(state.stage),
    500
  );

  // auto progress after delay.
  useTimeout(
    () => ctrl?.onClickEveryoneIsReady(false),
    disabled ? null : DEFAULT_DELAY_MS
  );

  if (!isCoordinator || state.stage !== 'present' || !ctrl) return null;

  return (
    <div className='w-full'>
      <EveryonesReadyButton
        animation='animatejit-[2s_ease_0s_infinite_plusing]'
        disabled={disabled}
        onClick={handleClick}
        hasHostedTutorial={state.shared?.hasHostedTutorial ?? false}
      >
        {disabled ? (
          <>
            <Loading
              imgClassName='w-4 h-4'
              containerClassName='flex-none'
              text=''
            />
            <>Preparing...</>
          </>
        ) : (
          <>Everyone's Ready!</>
        )}
      </EveryonesReadyButton>
      <div className='text-2xs text-red-002 text-center'>
        {err2s(commandChannelError)}
      </div>
    </div>
  );
}
