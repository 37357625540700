import { useEffectOnce } from 'react-use';

import { EnumsGamePackMakeup } from '@lp-lib/api-service-client/public';

import medal from '../../../assets/img/medal.png';
import { Modal } from '../../../components/common/Modal';
import { type GamePack } from '../../../types/game';

export function PairingMedalModal(props: {
  pack: GamePack;
  onShow: () => void;
  onComplete: () => void;
}): JSX.Element | null {
  useEffectOnce(() => {
    props.onShow();
  });

  return (
    <Modal stopPropagateMouseDown borderStyle='gray'>
      <div className='h-full flex flex-col items-center justify-center text-center text-white'>
        <header className='mt-10 mb-5 font-bold text-xl text-center'>
          <img src={medal} alt='medal' />
        </header>

        <main className='w-132 mx-20 flex-grow flex flex-col items-center'>
          <h1 className='text-2xl font-medium'>
            {`You completed ${props.pack.name}!`}
          </h1>
          <div className='mt-4 text-sms font-normal'>
            {props.pack.playbackSettings?.gameMakeup ===
            EnumsGamePackMakeup.GamePackMakeupMultipleLevels ? (
              <p className='text-center'>
                You've played every possible level of this game! <br />
                The leaderboards will only show your team's highest score across
                all levels. <br />
                You can replay any level as many times as you'd like to try to
                set the high score!
              </p>
            ) : (
              <p className='text-center'>
                You’ve played all the variations of this game that exist! Thank
                you for being such an avid user of Luna Park! You can replay as
                many times as you’d like, but new scores will not count towards
                this round’s leaderboard.
              </p>
            )}
          </div>
        </main>

        <footer className='mb-10 mt-8 flex justify-center'>
          <button onClick={props.onComplete} className='btn-primary w-33 h-10'>
            Done
          </button>
        </footer>
      </div>
    </Modal>
  );
}
