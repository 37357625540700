import { useShowOndHostingWarning } from './OnDGameHostingManager';

export function OndHostingWarning() {
  const showWarning = useShowOndHostingWarning();

  if (showWarning) {
    return (
      <div
        className={`h-10 flex items-center justify-center flex-shrink-0 
      bg-tertiary rounded-lg absolute bottom-3 left-1/2 transform-gpu 
      -translate-x-1/2 px-4 text-sms`}
      >
        Reconnecting to Remote Hosting Server...
      </div>
    );
  }

  return null;
}
