import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import { usePixelFxDraw, usePixelFxUpdate } from '../PixelFx/PixelFxProvider';
import { usePixelFxSceneControl } from '../PixelFx/PixelFxSceneProvider';

export function EmojisAnimationDrawer() {
  const api = usePixelFxSceneControl('emojis');

  const shouldTick = useSnapshot(api.state).emojisCount > 0;

  useEffect(() => {
    api.on();
    return () => api.off();
  }, [api]);

  usePixelFxDraw(shouldTick ? api.draw : null);
  usePixelFxUpdate(shouldTick ? api.update : null);

  return null;
}
