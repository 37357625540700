import isNumber from 'lodash/isNumber';
import startCase from 'lodash/startCase';
import { useMemo, useRef, useState } from 'react';

import {
  assertExhaustive,
  type IcebreakerBlock,
  IcebreakerBlockGameSessionStatus,
  IcebreakerMode,
  IcebreakerSelectNextStrategy,
} from '@lp-lib/game';

import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { TimeUtils } from '../../../../utils/time';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DoubleRightArrow,
} from '../../../icons/Arrows';
import { TimerIcon } from '../../../icons/TimerIcon';
import { useGameSessionStatus } from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { type CoordinatorControllerProps } from '../Common/CoordinatorController/CoordinatorController';
import { AutoProgressingIndicator } from '../Common/GamePlay/AutoProgressingIndicator';
import { useIcebreakerSharedAPI } from './IcebreakerBlockProvider';
import { IcebreakerUtils } from './utils';

function NextPlayerButton(props: CoordinatorControllerProps<IcebreakerBlock>) {
  const { selectedBlock } = props;
  const api = useIcebreakerSharedAPI();

  const actions = useMemo(() => {
    return [
      {
        key: 'random',
        label: 'Shuffle Player',
        className: 'text-sms',
        onClick: () => {
          const uid = api.pickNextPlayerUid(
            selectedBlock.fields.onStageSelection,
            IcebreakerSelectNextStrategy.Default
          );
          api.nextPlayer(uid);
        },
      },
      {
        key: 'manual',
        label: 'Manually Choose Player',
        className: 'text-3xs',
        onClick: () => {
          api.toggleNextPlayerPicker(true);
        },
      },
    ];
  }, [api, selectedBlock.fields.onStageSelection]);
  const [expanded, setExpanded] = useState(false);
  const [selectedActionKey, setSelectedActionKey] = useState(actions[0].key);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    setExpanded(false);
  });

  const showActions = useMemo(() => {
    const selectedAction = actions.find(
      (action) => action.key === selectedActionKey
    );
    if (!selectedAction) return actions;
    if (!expanded) return [selectedAction];

    return [
      selectedAction,
      ...actions.filter((action) => action.key !== selectedActionKey),
    ];
  }, [actions, expanded, selectedActionKey]);

  return (
    <div ref={ref} className='w-full flex gap-2'>
      <div className='flex-1 rounded-xl overflow-hidden border border-secondary'>
        {showActions.map((action, index) => {
          return (
            <button
              key={action.key}
              type='button'
              className={`btn-secondary rounded-none border-none  w-full h-10 flex items-center justify-between px-3 font-medium ${action.className}`}
              onClick={() => {
                setSelectedActionKey(action.key);
                setExpanded(!expanded);
              }}
            >
              {action.label}
              {index === 0 &&
                (expanded ? (
                  <ArrowUpIcon className='w-2.5 h-2.5 fill-current' />
                ) : (
                  <ArrowDownIcon className='w-2.5 h-2.5 fill-current' />
                ))}
            </button>
          );
        })}
      </div>

      <button
        type='button'
        className='btn-secondary w-10 h-10'
        onClick={() => {
          const selectedAction = actions.find(
            (action) => action.key === selectedActionKey
          );
          if (!selectedAction) return;
          selectedAction.onClick();
        }}
      >
        GO
      </button>
    </div>
  );
}

// only for selectPlayer is off
function ContinueGameButton(
  props: CoordinatorControllerProps<IcebreakerBlock>
) {
  const api = useIcebreakerSharedAPI();

  const label =
    props.selectedBlock.fields.mode === IcebreakerMode.ChatPack
      ? 'Next Card'
      : 'Continue Game';

  const handleClick = () => {
    const currentCard = api.getCurrentCard();
    if (!currentCard) return;

    const mostVotedIndex = api.getMostVotedIndex(currentCard.choiceMap);

    if (
      currentCard.phase === 'active' &&
      // if there is one voted, reveal it
      isNumber(mostVotedIndex)
    ) {
      api.revealCard(mostVotedIndex);
      return;
    }

    api.nextCard();
  };

  return (
    <button
      type='button'
      className='btn-secondary w-full h-10 flex items-center justify-center gap-2.5 text-sms font-medium'
      onClick={handleClick}
    >
      <DoubleRightArrow />
      {label}
    </button>
  );
}

function GameStart(props: CoordinatorControllerProps<IcebreakerBlock>) {
  return (
    <div className='flex flex-col items-center gap-2.5'>
      {IcebreakerUtils.ShouldSelectOnStagePlayer(props.selectedBlock) ? (
        <NextPlayerButton {...props} />
      ) : (
        <ContinueGameButton {...props} />
      )}

      <button
        type='button'
        className={`btn-secondary w-full h-10 flex items-center justify-center gap-2.5 text-sms font-medium truncate`}
        onClick={() => ondWaitEnd()}
      >
        <DoubleRightArrow className='w-4 h-4 fill-current' />
        {`Finish ${startCase(props.selectedBlock.fields.gameName)}`}
      </button>

      {props.selectedBlock.fields.gameTimeSec > 0 && (
        <AutoProgressingIndicator
          formatText={(remainingSec) => (
            <>
              <TimerIcon />
              {`Time: ${TimeUtils.DurationFormattedHHMMSS(
                remainingSec * 1000
              )}`}
            </>
          )}
        />
      )}
    </div>
  );
}

export function IcebreakerBlockCoordinatorController(
  props: CoordinatorControllerProps<IcebreakerBlock>
): JSX.Element | null {
  const gss = useGameSessionStatus<IcebreakerBlockGameSessionStatus>();

  switch (gss) {
    case IcebreakerBlockGameSessionStatus.GAME_START:
      return <GameStart {...props} />;
    case IcebreakerBlockGameSessionStatus.RESULTS:
    case IcebreakerBlockGameSessionStatus.LOADED:
    case IcebreakerBlockGameSessionStatus.GAME_INIT:
    case IcebreakerBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
