import { useNavigate } from '@remix-run/react';
import { useEffect, useRef } from 'react';

import { useMarkAsAwayAnalytics } from '../../analytics/markAsAway';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useVenueMode } from '../../hooks/useVenueMode';
import logger from '../../logger/logger';
import { type AwayMessage, VenueMode } from '../../types';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { useMyInstance } from '../Player';
import { useGetStreamSessionId } from '../Session';
import { useCreateTeam, useJoinTeam } from '../TeamAPI/TeamV1';

const log = logger.scoped('mark-away');

function useTriggerUserMarkedAsAwayModal() {
  const me = useMyInstance();
  const venueMode = useVenueMode();
  const joinTeam = useJoinTeam();
  const createTeam = useCreateTeam();
  const getStreamSessionId = useGetStreamSessionId();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  const analytics = useMarkAsAwayAnalytics();
  const navigate = useNavigate();

  return useLiveCallback(async (message: AwayMessage) => {
    log.info('triggering marked as away modal');

    analytics.trackMarkedAsAwayViewed();
    const streamSessionId = getStreamSessionId();
    await confirmCancel({
      kind: 'confirm-cancel',
      boxDimensionsClassName: 'py-1 px-4 max-w-85 min-h-45',
      prompt: (
        <>
          <ConfirmCancelModalHeading className='pt-3'>
            Welcome Back!
          </ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='text-sms font-normal py-2'>
            {me?.clientId === message.byClientId ? (
              <>Ready to jump back in? Click below to rejoin the game.</>
            ) : message.message ? (
              <>{message.message}</>
            ) : (
              <>
                You were marked as away. When you’re ready to play again click
                below to rejoin the game.
              </>
            )}
          </ConfirmCancelModalText>
        </>
      ),
      confirmBtnLabel: 'Rejoin',
      confirmOnly: true,
    });

    if (message.redirectTo) {
      // if there's a redirectTo, it means the user was never redirected to this url and they were able to
      // click the rejoin button. We should navigate them to the redirectTo url.
      navigate(message.redirectTo);
      return;
    }

    analytics.trackMarkedAsAwayRejoined();

    // client is back, let's get them back in the game.
    log.info('attempting to rejoin game', { teamId: message.teamId });
    const currentStreamSessionId = getStreamSessionId();
    if (venueMode === VenueMode.Lobby) {
      log.info('in lobby, creating new team');
      await createTeam({
        memberId: me?.clientId,
        username: me?.username,
        cohost: me?.cohost,
        debug: 'rejoin-create-team',
      });
    } else if (me?.clientId && streamSessionId === currentStreamSessionId) {
      log.info('in game, attempting to rejoin team');
      await joinTeam({
        memberId: me?.clientId,
        teamId: message.teamId,
        debug: 'rejoin-team',
      });
      // if we failed to join a team, the user should see the join team interstitial
    } else {
      log.info('in game, but the stream session has changed');
      // the user should see the join team interstitial
    }
  });
}

export function MarkedAsAway() {
  const me = useMyInstance();
  const markAsAwayMessage = me?.away;
  const triggeredAt = useRef<AwayMessage['timestamp'] | null>(null);
  const triggerUserMarkedAsAwayModal = useTriggerUserMarkedAsAwayModal();
  const navigate = useNavigate();

  useEffect(() => {
    async function run() {
      if (me?.teamId || !markAsAwayMessage || triggeredAt.current) return;

      triggeredAt.current = markAsAwayMessage.timestamp;
      if (markAsAwayMessage.redirectTo) {
        navigate(markAsAwayMessage.redirectTo);
      }

      await triggerUserMarkedAsAwayModal(markAsAwayMessage);
      triggeredAt.current = null;
    }
    run();
  }, [markAsAwayMessage, me?.teamId, navigate, triggerUserMarkedAsAwayModal]);

  return null;
}
