import { useEffect, useRef } from 'react';

import { getFeatureQueryParamNumber } from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';
import { NotificationType } from '../../types';
import { uuidv4 } from '../../utils/common';
import { useEXPSDetractorPct100 } from '../ExperienceScore';
import { useLiteModeEnabled } from '../LiteMode/LiteModeContext';
import { useNotificationDataSource } from '../Notification/Context';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';

const log = logger.scoped('lite-mode');
const MIN_DETRACT_PCT_100_ALLOWED = getFeatureQueryParamNumber(
  'lite-mode-notif-trigger-detractor-score'
);

export function LiteModeNotificationDispatcher(): null {
  const nds = useNotificationDataSource();
  const liteModeEnabled = useLiteModeEnabled();
  const expsDetractPct100 = useEXPSDetractorPct100();
  const dispatched = useRef(false);
  const myClientId = useMyClientId();

  useEffect(() => {
    if (liteModeEnabled || dispatched.current) return;
    if (expsDetractPct100 < MIN_DETRACT_PCT_100_ALLOWED) return;

    dispatched.current = true;
    log.info('dispatching notification', {
      detractPct100: expsDetractPct100,
    });

    nds.send({
      id: uuidv4(),
      toUserClientId: myClientId,
      type: NotificationType.LiteModeSuggestion,
      createdAt: Date.now(),
    });
  }, [dispatched, expsDetractPct100, liteModeEnabled, myClientId, nds]);

  return null;
}
