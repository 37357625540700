import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization';
import { useAnalytics } from './AnalyticsContext';

class InstructionBlockAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    private readonly orgId: string | null
  ) {}

  trackEveryonesReadyClicked(props: {
    includeHostedTutorial: boolean | null; // null in case it's unavailable
  }): void {
    this.analytics.track(
      'Instruction Block Everyones Ready Clicked',
      this.buildProps(props)
    );
  }

  private buildProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useInstructionBlockAnalytics(): InstructionBlockAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();
  return useMemo(
    () => new InstructionBlockAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
