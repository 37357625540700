import { useEffect, useMemo, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { EnumsSpotlightBlockStageLayout } from '@lp-lib/api-service-client/public';
import {
  type SpotlightBlock,
  type SpotlightBlockGameSessionStatus,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { type Team, type TeamId } from '../../../../types';
import { Menu, MenuItem, NestedMenuItem } from '../../../common/ActionMenu';
import { type BlockId } from '../../../GameRecorder/types';
import { ArrowDownIcon } from '../../../icons/Arrows';
import { useAmICohost } from '../../../Player';
import { useTeamGetter, useTeams } from '../../../TeamAPI/TeamV1';
import { useGameSessionStatus } from '../../hooks';
import { type CoordinatorControllerProps } from '../Common/CoordinatorController/CoordinatorController';
import { useStableBlock } from '../Common/hooks';
import { useSpotlightControlAPI } from './SpotlightBlockProvider';
import { SpotlightPreSelectedTeamOrder } from './types';

export function SpotlightBlockCoordinatorController(
  props: CoordinatorControllerProps<SpotlightBlock>
): JSX.Element | null {
  const block = useStableBlock(props.selectedBlock);
  const gss = useGameSessionStatus<SpotlightBlockGameSessionStatus>();
  const amICohost = useAmICohost();

  if (
    !amICohost ||
    !gss ||
    block.fields.stageLayout !==
      EnumsSpotlightBlockStageLayout.SpotlightBlockStageLayoutInterview
  ) {
    return null;
  }

  return <Controls block={block} />;
}

function Controls(props: { block: SpotlightBlock }): JSX.Element | null {
  const control = useSpotlightControlAPI();
  const getTeam = useTeamGetter();
  const [systemSelectedTeam, setSystemSelectedTeam] = useState<Team | null>(
    null
  );
  const handleClick = useLiveCallback((teamId: TeamId) => {
    control?.manuallySpotlightTeam(teamId, props.block.id);
  });
  useEffect(() => {
    const preselection = props.block.fields.preselectedTeamOrder;
    const showSystemSelection =
      preselection === SpotlightPreSelectedTeamOrder.Coordinator ||
      preselection === SpotlightPreSelectedTeamOrder.SystemSelected;
    if (!showSystemSelection) return;

    control?.getSystemSelectedTeam(props.block.id).then((teamId) => {
      const team = getTeam(teamId);
      if (team) {
        setSystemSelectedTeam(team);
      }
    });
  }, [control, getTeam, props.block]);

  return (
    <div className='w-full bg-secondary rounded-xl px-4 py-3 space-y-2'>
      <div className='text-secondary text-3xs uppercase tracking-wide font-bold'>
        Spotlight Interview
      </div>

      {systemSelectedTeam && (
        <button
          type='button'
          className='btn-secondary w-full text-sms px-2 py-1'
          onClick={() => handleClick(systemSelectedTeam.id)}
        >
          Bring{' '}
          <span
            className='font-bold'
            style={{ color: systemSelectedTeam.color ?? '#FFFFFF' }}
          >
            {systemSelectedTeam.name}
          </span>{' '}
          on Stage
        </button>
      )}

      <StageMenu blockId={props.block.id} />
    </div>
  );
}

function StageMenu(props: { blockId: BlockId }) {
  const [controlledVisibility, setControlledVisibility] = useState(false);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'click',
      interactive: true,
      visible: controlledVisibility,
      onVisibleChange: setControlledVisibility,
    });
  const hide = useLiveCallback(() => setControlledVisibility(false));
  const styles = useMemo(() => {
    return {
      sizing: 'w-full',
    };
  }, []);

  return (
    <div className='relative w-full'>
      <button
        className='btn-secondary w-full h-10 text-sms flex items-center justify-center gap-1'
        ref={setTriggerRef}
        type='button'
      >
        Options
        <ArrowDownIcon className='w-3.5 h-3.5 fill-current' />
      </button>
      {visible && (
        <Menu ref={setTooltipRef} styles={styles} {...getTooltipProps()}>
          <BringOnStageMenuItem blockId={props.blockId} hide={hide} />
          <ClearStageMenuItem hide={hide} />
        </Menu>
      )}
    </div>
  );
}

function ClearStageMenuItem(props: { hide: () => void }) {
  const control = useSpotlightControlAPI();
  const handleClick = useLiveCallback(() => {
    control?.manuallyClearStage();
    props.hide();
  });

  return <MenuItem text='Clear Stage' onClick={handleClick} />;
}

function BringOnStageMenuItem(props: { blockId: BlockId; hide: () => void }) {
  const control = useSpotlightControlAPI();
  const handleClick = useLiveCallback((teamId: TeamId) => {
    control?.manuallySpotlightTeam(teamId, props.blockId);
    props.hide();
  });

  const teams = useTeams({
    active: true,
    updateStaffTeam: true,
    excludeStaffTeam: true,
    excludeCohostTeam: true,
  });
  const menuItems = useMemo(() => {
    return teams.map((team) => (
      <MenuItem
        key={team.id}
        text={
          <span style={{ color: `${team.color ?? '#FFFFFF'}` }}>
            {team.name}
          </span>
        }
        onClick={() => handleClick(team.id)}
      />
    ));
  }, [handleClick, teams]);

  return <NestedMenuItem text='Bring On Stage'>{menuItems}</NestedMenuItem>;
}
