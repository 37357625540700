import { type CSSProperties, useState } from 'react';

import {
  assertExhaustive,
  type MarketingBlock,
  MarketingBlockGameSessionStatus,
} from '@lp-lib/game';

import { DoubleRightArrow } from '../../../icons/Arrows';
import {
  useGameSessionStatus,
  useOndGameState,
  useOndWaitModeInfo,
} from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { type CoordinatorControllerProps } from '../Common/CoordinatorController/CoordinatorController';
import { AutoProgressingIndicator } from '../Common/GamePlay/AutoProgressingIndicator';
import { useStableBlock } from '../Common/hooks';

function Controls(props: { block: MarketingBlock }): JSX.Element | null {
  const { skippableAfterSec } = props.block.fields;
  const { remainingSec, userSkippableMaxDurationSec } = useOndWaitModeInfo();
  const state = useOndGameState();
  const [skipping, setSkipping] = useState(false);

  const skipAfterSec = userSkippableMaxDurationSec - skippableAfterSec;
  const remainingWaitUntilSkipSec = remainingSec - skipAfterSec;
  const readyForSkip = remainingWaitUntilSkipSec <= 0;
  const handleSkip = async () => {
    if (skipping) return;
    setSkipping(true);
    await ondWaitEnd();
  };

  if (state !== 'running' || skipping) return null;
  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='w-full h-10 relative'>
        {readyForSkip ? (
          <>
            <div
              className={`absolute bg-blue-001 inset-0 rounded-xl transform animate-plusing`}
              style={
                {
                  '--tw-plusing-duration': '2s',
                } as CSSProperties
              }
            />
            <button
              type='button'
              className='w-full absolute inset-0 btn-primary flex items-center justify-center font-bold'
              onClick={handleSkip}
              disabled={!readyForSkip || skipping}
            >
              <div className='mr-2'>Continue Experience</div>
              <DoubleRightArrow />
            </button>
          </>
        ) : (
          <div className='w-full text-center text-2xs text-tertiary font-bold mt-2.5'>
            You may continue in {remainingWaitUntilSkipSec}s...
          </div>
        )}
      </div>
      {readyForSkip && <AutoProgressingIndicator className='mt-2.5' />}
    </div>
  );
}

export function MarketingBlockCoordinatorController(
  props: CoordinatorControllerProps<MarketingBlock>
): JSX.Element | null {
  const block = useStableBlock(props.selectedBlock);
  const gss = useGameSessionStatus<MarketingBlockGameSessionStatus>();

  switch (gss) {
    case MarketingBlockGameSessionStatus.PRESENTING:
      return <Controls block={block} />;
    case MarketingBlockGameSessionStatus.LOADED:
    case MarketingBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
