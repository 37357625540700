import { useEffect } from 'react';

import { MediaFormatVersion } from '@lp-lib/media';

import { MediaUtils } from '../../utils/media';
import { useDeviceAPI } from '../Device/Context';
import { useMaybeVideoEffectsMixer } from '../Device/video-stream-mixer';
import { useOrg } from '../Lobby/hooks';
import { useAmICohost } from '../Player';
import { useVenueOrgId } from '../Venue/useVenueOrgId';
import { useVenueOrgScoreboardColor } from '../VenueOrgLogoAverageColor/VenueOrgLogoAverageColorProvider';
import { useCohostNamedPosition } from './CohostPositionManagerProvider';

function useLoadOrgLogoMediaFormat() {
  const venueOrg = useVenueOrgId();
  const { data: org } = useOrg(venueOrg ?? undefined);
  const logoMediaFormat = MediaUtils.PickMediaFormat(org?.logo, {
    priority: [MediaFormatVersion.SM],
  });
  return logoMediaFormat;
}

export function CohostSynchronizeOrgLogo(props: { ready: boolean }) {
  const deviceAPI = useDeviceAPI();
  const vbgMixer = useMaybeVideoEffectsMixer(deviceAPI.mixer);
  const orgMediaFormat = useLoadOrgLogoMediaFormat();
  const position = useCohostNamedPosition();
  const brandColor = useVenueOrgScoreboardColor();
  const isCohost = useAmICohost();

  useEffect(() => {
    if (
      !vbgMixer ||
      !orgMediaFormat ||
      !brandColor ||
      !props.ready ||
      !isCohost
    )
      return;

    vbgMixer.updateVideoEffectsSettings({
      orgIconSrc: { mediaFormat: orgMediaFormat },
      orgIcon: {
        brandCSSColor: brandColor.cssRGBA(1),
      },
    });
  }, [brandColor, isCohost, orgMediaFormat, position, props.ready, vbgMixer]);

  return null;
}
