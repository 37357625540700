import pluralize from 'pluralize';
import { useMemo, useState } from 'react';

import { type OverRoastedBlock } from '@lp-lib/game';

import type { TeamId } from '../../../../types';
import { Loading } from '../../../Loading';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { type CoordinatorControllerProps } from '../Common/CoordinatorController/CoordinatorController';
import {
  useOverRoastedGame,
  useOverRoastedGameSettings,
  useOverRoastedSummaryMap,
  useOverRoastedTutorialProgress,
} from './OverRoastedProvider';
import { GameState } from './types';

export function OverRoastedBlockTeamProgressSummary(props: {
  block: OverRoastedBlock;
  teamId: TeamId;
}) {
  const summary = useOverRoastedSummaryMap();
  const completed = useMemo(() => {
    return summary?.[props.teamId]?.completedOrders ?? 0;
  }, [summary, props.teamId]);
  return (
    <>
      Progress: {completed} {pluralize('order', completed)} completed
    </>
  );
}

function SkipTutorialButton(): JSX.Element | null {
  const [running, setRunning] = useState(false);

  const handleClick = async () => {
    setRunning(true);
    try {
      await ondWaitEnd();
    } catch (error) {
      setRunning(false);
      throw error;
    }
  };
  return (
    <div className='w-full h-10'>
      <button
        type='button'
        className='w-full h-full btn-primary flex items-center justify-center gap-2'
        onClick={handleClick}
        disabled={running}
      >
        {running && <Loading text='' />}
        <div>Skip Tutorial</div>
      </button>
    </div>
  );
}

export function OverRoastedBlockCoordinatorController(
  _props: CoordinatorControllerProps<OverRoastedBlock>
): JSX.Element | null {
  const settings = useOverRoastedGameSettings();
  const game = useOverRoastedGame();
  const progress = useOverRoastedTutorialProgress();
  const tutorialMode =
    settings.tutorialMode &&
    game?.state === GameState.InProgress &&
    !!progress?.step;

  if (tutorialMode) {
    return <SkipTutorialButton />;
  }
  return null;
}
