import { Modal } from '../../../components/common/Modal';
import { useTeamIsFullModal } from '../../../components/TeamAPI/TeamV1';

const TeamFullModel = (): JSX.Element => {
  const [showing, hide] = useTeamIsFullModal();

  const handleClose = () => {
    hide();
  };

  if (!showing) return <></>;

  return (
    <Modal borderStyle='gray' bgStyle='black-blur'>
      <div className='flex flex-col w-80 p-6 items-center justify-center'>
        <header className='text-white text-2xl text-center'>
          Team is Full!
        </header>
        <div className='text-white text-sms text-center my-4'>
          We couldn’t add you back to your previous Team, because it became
          full. Please create or join another Team!
        </div>
        <div>
          <button
            type='button'
            className='btn-primary w-30 h-10 mx-1'
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export { TeamFullModel };
